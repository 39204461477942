// src/components/Dashboard/CreateChatbotModal.tsx

import React, { useEffect, useState } from 'react';
import { Modal, Form, Input } from 'antd';
import { ChatbotCreationData } from '../../../types';

interface CreateChatbotModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onCreateOrEdit: (chatbotData: ChatbotCreationData) => void;
  initialValues?: ChatbotCreationData | null;
}

const CreateChatbotModal: React.FC<CreateChatbotModalProps> = ({
  isOpen,
  onRequestClose,
  onCreateOrEdit,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [form, initialValues]);

  const handleOk = () => {
    setConfirmLoading(true);
    form
      .validateFields()
      .then((values: ChatbotCreationData) => {
        onCreateOrEdit({
          name: values.name.trim(),
          industry: values.industry.trim(),
          description: values.description?.trim() || '',
          greetingMessage: values.greetingMessage?.trim() || '',
          instructions: values.instructions.trim(),
        });
        form.resetFields();
      })
      .catch((info) => {
        setConfirmLoading(false);
        console.warn('Validation Failed:', info);
      });
  };

  const handleCancel = () => {
    onRequestClose();
    form.resetFields();
  };

  return (
    <Modal
      open={isOpen}
      title={initialValues ? 'Edit Chatbot' : 'Create New Chatbot'}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={initialValues ? 'Save' : 'Create'}
      cancelText="Cancel"
      destroyOnClose
    >
      <Form form={form} layout="vertical" name="create_edit_chatbot_form">
        <Form.Item
          name="name"
          label="Chatbot Name"
          rules={[
            { required: true, message: 'Please input the chatbot name!' },
          ]}
        >
          <Input placeholder="A name to identify this bot" />
        </Form.Item>

        <Form.Item
          name="industry"
          label="Industry"
          rules={[
            {
              required: true,
              message:
                'Please provide the industry in which this bot will be used!',
            },
          ]}
        >
          <Input placeholder="E.g: Finance" />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input.TextArea
            placeholder="(Optional) Describe what the bot does"
            rows={4}
          />
        </Form.Item>

        <Form.Item name="greetingMessage" label="Greeting Message">
          <Input.TextArea
            placeholder="Hi, I am VinTin. I can help you form a strategy or give general advise on how to reach your financial goals. How can I help you today?"
            rows={4}
          />
        </Form.Item>

        <Form.Item
          name="instructions"
          label="Instructions"
          rules={[
            {
              required: true,
              message: 'Please provide some instructions for the bot!',
            },
          ]}
        >
          <Input.TextArea
            placeholder="Come up with a hyper personalised strategy to meet users financial goals by asking relevant questions and giving very concise answers. End by saying that a financial expert will reach out. Always maintain a professional tone."
            rows={4}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateChatbotModal;
