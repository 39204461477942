import React, { useState, useEffect } from 'react';
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
  DocumentReference,
} from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import ChatbotCard from './ChatbotCard';
import CreateChatbotModal from './CreateChatbotModal';
import { Chatbot, ChatbotCreationData } from '../../../types';
import {
  Button,
  CircularProgress,
  Grid2 as Grid,
  Stack,
  Typography,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface ChatbotsProps {
  vendorRef: DocumentReference;
}

const Chatbots: React.FC<ChatbotsProps> = ({ vendorRef }) => {
  const [chatbots, setChatbots] = useState<Chatbot[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editingChatbot, setEditingChatbot] = useState<Chatbot | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const chatbotsRef = collection(db, 'chatbots');
    const q = query(chatbotsRef, where('vendorRefId', '==', vendorRef));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const bots: Chatbot[] = [];
      snapshot.forEach((doc) => {
        bots.push({ id: doc.id, ...doc.data() } as Chatbot);
      });
      setChatbots(bots);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [vendorRef]);

  const handleCreateOrEditChatbot = async (
    chatbotData: ChatbotCreationData
  ) => {
    try {
      if (editingChatbot) {
        await setDoc(
          doc(db, 'chatbots', editingChatbot.id),
          {
            ...chatbotData,
            vendorRefId: vendorRef,
            published: false,
            updatedAt: serverTimestamp(),
          },
          { merge: true }
        );
      } else {
        await addDoc(collection(db, 'chatbots'), {
          ...chatbotData,
          vendorRefId: vendorRef,
          createdAt: serverTimestamp(),
          published: false,
        });
      }
      setIsModalOpen(false);
      setEditingChatbot(null);
    } catch (error) {
      console.error('Error creating chatbot:', error);
    }
  };

  const setModalParams = (chatbot: Chatbot | null, openModal: boolean) => {
    setEditingChatbot(chatbot);
    setIsModalOpen(openModal);
  };

  // TODO: introduce loading to show while chatbots are fetched
  return (
    <Grid container spacing={2} className="p-4" sx={{ maxWidth: '95vw' }}>
      <Grid size={12}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <Typography variant="h4" gutterBottom>
            Chatbots
          </Typography>
          <Button
            onClick={() => setModalParams(null, true)}
            variant="contained"
            startIcon={<AddCircleIcon />}
          >
            New
          </Button>
        </Stack>
      </Grid>
      {loading ? (
        <CircularProgress />
      ) : (
        <Stack
          direction="row"
          spacing={{ xs: 1, sm: 2, md: 3 }}
          useFlexGap
          sx={{ flexWrap: 'wrap' }}
        >
          {chatbots.map((chatbot) => (
            <ChatbotCard
              key={chatbot.id}
              onEdit={() => setModalParams(chatbot, true)}
              chatbot={chatbot}
            />
          ))}
        </Stack>
      )}

      {/* Chatbot Cards */}
      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"></div> */}

      {/* Create Chatbot Modal */}
      {isModalOpen && (
        <CreateChatbotModal
          isOpen={isModalOpen}
          onRequestClose={() => setModalParams(null, false)}
          onCreateOrEdit={handleCreateOrEditChatbot}
          initialValues={editingChatbot}
        />
      )}
    </Grid>
  );
};

export default Chatbots;
