// src/components/LandingPage.tsx

import React from 'react';
import Metadata from './Metadata';
import { Link, Typography } from '@mui/material';

const LandingPage: React.FC = () => {
  return (
    <>
      <Metadata />
      <div className="min-h-screen bg-gray-50 flex flex-col">
        {/* Navigation Bar */}
        <nav className="bg-white shadow">
          <div className="container mx-auto px-6 py-4 flex justify-between items-center">
            <Link
              href="/"
              underline="none"
              sx={{ display: 'flex', alignItems: 'start' }}
            >
              <Typography variant="h4" color="primary.dark">
                VinTin
              </Typography>
              <Typography variant="body2" color="primary.dark">
                (beta)
              </Typography>
            </Link>
            <Link
              href="/signin"
              variant="subtitle1"
              target="_blank"
              rel="noopener"
              color="primary.dark"
            >
              SIGN IN
            </Link>
            {/* Add navigation links if needed */}
          </div>
        </nav>

        {/* Main Content */}
        <main className="flex-grow container mx-auto px-6 py-12 flex flex-col items-center">
          <h1 className="text-4xl font-bold text-center text-gray-800 mb-6">
            Unlock the Power of Conversation with VinTin
          </h1>
          <p className="text-lg text-center text-gray-600 mb-8">
            Engage your customers like never before using our cutting-edge
            conversational AI solutions. At VinTin, we understand the importance
            of meaningful customer interactions. Our GPT-powered chatbots are
            designed to provide personalized experiences that drive engagement
            and boost conversions.
          </p>
          <p className="text-lg text-center text-gray-600 mb-2">
            Don't miss out on the opportunity to stay ahead of the competition.
            Let VinTin elevate your customer engagement strategy today.
          </p>

          {/* Additional Content */}
          <section className="mt-12 space-y-8">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Enhance Customer Engagement
                </h2>
                <p className="text-gray-600">
                  VinTin's AI-powered chat solutions help you connect with
                  customers in their own time, leveraging industry and your
                  expertise at scale, providing personalized experiences that
                  drive engagement and satisfaction.
                </p>
              </div>
              {/* Placeholder for an image */}
              <div className="md:w-1/2 mt-6 md:mt-0 md:pl-12">
                <img
                  src="/images/engagement.webp"
                  alt="Customer Engagement"
                  className="w-full h-auto"
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center">
              {/* Placeholder for an image */}
              <div className="md:w-1/2 md:order-2">
                <img
                  src="/images/lead.jpeg"
                  alt="Generate Leads"
                  className="w-full h-auto"
                />
              </div>
              <div className="md:w-1/2 md:pr-12 mt-6 md:mt-0 md:order-1">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Generate High-Quality Leads
                </h2>
                <p className="text-gray-600">
                  Our platform not only engages visitors but also captures
                  valuable insights, turning interactions into actionable leads
                  for your business.
                </p>
              </div>
            </div>
            <div className="text-center">
              {
                <a
                  href="/signup"
                  className="bg-blue-600 text-white px-8 py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-200"
                >
                  Get Started
                </a>
              }
            </div>
          </section>
        </main>

        {/* Footer */}
        <footer className="bg-white py-4">
          <div className="container mx-auto px-6 text-center text-gray-600">
            &copy; {new Date().getFullYear()} VinTin. All rights reserved.
          </div>
        </footer>
      </div>
    </>
  );
};

export default LandingPage;

// const LandingPage: React.FC = () => {
//   return (
//     <>
//       <Metadata />
//       {/* <AppBar position="static" color="transparent" elevation={0}>
//         <Toolbar>
//           <Button color="primary" href="/" style={{ flexGrow: 1 }}>
//             Vintin.ai
//           </Button>
//         </Toolbar>
//       </AppBar> */}
//       <Container
//         maxWidth="md"
//         style={{ textAlign: 'center', marginTop: '4rem' }}
//       >
//         <Typography variant="h2" gutterBottom>
//           Welcome to Vintin.ai
//         </Typography>
//         <Typography variant="h5" component="p">
//           Revolutionizing your sales process by eliminating the need of cold
//           calls for your business.
//         </Typography>
//         {/* <Button variant="contained" color="primary" size="large" href="/signup">
//           Get Started
//         </Button> */}
//       </Container>
//       <Box mt={8} bgcolor="#f5f5f5" py={8}>
//         <Container maxWidth="md">
//           <Typography variant="h4" gutterBottom>
//             Why Choose Vintin.ai?
//           </Typography>
//           <Typography variant="body1" component="p">
//             Vintin.ai offers a seamless and efficient alternative to traditional
//             cold calling, enhancing both your and your customer's experience.
//             Our AI-driven speciality solution ensures better engagement, higher
//             conversion rates, and improved customer satisfaction.
//           </Typography>
//         </Container>
//       </Box>
//       <Box bgcolor="primary.main" color="primary.contrastText" py={4}>
//         <Container maxWidth="md" style={{ textAlign: 'center' }}>
//           <Typography variant="h6">
//             Ready to transform your communication strategy?
//           </Typography>
//           <Button
//             variant="contained"
//             color="inherit"
//             size="large"
//             onClick={handleClickOpen}
//           >
//             Get Started
//           </Button>
//         </Container>
//       </Box>
//     </>
//   );
// };

// export default LandingPage;
