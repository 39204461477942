import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Message } from '../../types';
import { Box, Paper, Typography } from '@mui/material';

interface ChatMessageProps {
  message: Message;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ message }) => {
  const isUser = message.role === 'user';

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isUser ? 'flex-end' : 'flex-start',
      }}
    >
      <Paper
        elevation={isUser ? 1 : 0}
        sx={{
          maxWidth: isUser ? '70%' : '95%',
          padding: 1,
          borderRadius: 2,
          bgcolor: isUser ? 'primary.main' : 'grey.100',
          color: isUser ? 'primary.contrastText' : 'text.primary',
        }}
      >
        {isUser ? (
          <Typography>{message.content}</Typography>
        ) : (
          <ReactMarkdown
            components={{
              p(props) {
                const { node, ...rest } = props;
                return (
                  <p
                    style={{ lineHeight: 1.65, letterSpacing: '0.2px' }}
                    {...rest}
                  />
                );
              },
              ol(props) {
                const { node, ...rest } = props;
                return (
                  <ol
                    style={{
                      paddingLeft: '1.5rem',
                      paddingTop: '0.25rem',
                      paddingBottom: '0.25rem',
                      listStyleType: 'disc',
                    }}
                    {...rest}
                  />
                );
              },
              ul(props) {
                const { node, ...rest } = props;
                return (
                  <ul
                    style={{
                      paddingLeft: '1.5rem',
                      paddingTop: '0.25rem',
                      paddingBottom: '0.25rem',
                      listStyleType: 'disc',
                    }}
                    {...rest}
                  />
                );
              },
            }}
          >
            {message.content}
          </ReactMarkdown>
        )}
      </Paper>
    </Box>
  );
};

export default ChatMessage;
