/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { db, gptChat } from '../../firebaseConfig';
import {
  collection,
  doc,
  DocumentReference,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';

import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';
import { Message } from '../../types';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';

interface ChatAppProps {
  initialQuery?: string;
}

let chatId: DocumentReference;

const ChatApp: React.FC<ChatAppProps> = ({ initialQuery }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [vendorReference, setVendorReference] = useState<DocumentReference>();
  const [prompt, setPrompt] = useState<string>('');
  const { chatBotId = 'WvuVwxAK8RN4YQ7xkbgm' } = useParams();

  useEffect(() => {
    chatId = doc(collection(db, 'chats'));
    const fetchAndSetVendor = async () => {
      const chatbotDocRef = doc(db, `chatbots/${chatBotId}`);
      const chatbotDocSnap = await getDoc(chatbotDocRef);
      if (chatbotDocSnap.exists()) {
        const { vendorRefId, greetingMessage, systemPrompt } =
          chatbotDocSnap.data();
        setVendorReference(vendorRefId);
        setPrompt(systemPrompt);
        if (greetingMessage) {
          setMessages([{ role: 'assistant', content: greetingMessage }]);
        }
        setIsLoading(false);
      } else {
        console.error(
          'Chatbot does not exist. User should try with correct url or refresh.',
          chatBotId
        );
        setIsLoading(false);
      }
    };
    fetchAndSetVendor();
  }, [chatBotId]);

  const handleSendMessage = async (input: string) => {
    setIsLoading(true);

    let newMessages: Message[] = [
      ...messages,
      { content: input, role: 'user' },
    ];
    setMessages(newMessages);
    try {
      await setDoc(chatId, {
        messages: newMessages,
        updatedAt: serverTimestamp(),
        leadGenerated: false,
        vendorRefId: vendorReference,
        chatbotRefId: doc(db, `chatbots/${chatBotId}`),
      });
      const response = await gptChat([
        { role: 'system', content: prompt },
        ...newMessages,
      ]);
      const aiMessage = response.data;

      newMessages = [...newMessages, { content: aiMessage, role: 'assistant' }];

      setMessages(newMessages);
      await setDoc(chatId, {
        messages: newMessages,
        updatedAt: serverTimestamp(),
        leadGenerated: false,
        vendorRefId: vendorReference,
        chatbotRefId: doc(db, `chatbots/${chatBotId}`),
      });
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (prompt) {
      if (initialQuery) {
        handleSendMessage(initialQuery);
      }
    }
  }, [prompt, initialQuery]);

  return (
    <Stack sx={{ height: initialQuery ? '90vh' : '100vh' }}>
      <ChatWindow messages={messages} isLoading={isLoading} />
      <ChatInput onSendMessage={handleSendMessage} isLoading={isLoading} />
    </Stack>
  );
};

export default ChatApp;
