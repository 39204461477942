import React, { useEffect, useMemo, useState } from 'react';
import { createTheme } from '@mui/material/styles';
import Person3Icon from '@mui/icons-material/Person3';
import TimelineIcon from '@mui/icons-material/Timeline';
import FlareIcon from '@mui/icons-material/FlareOutlined';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import type { Navigation, Router, Session } from '@toolpad/core';
import { doc, DocumentReference, getDoc } from 'firebase/firestore';
import { useAuth } from '../../AuthContext';
import { auth, db } from '../../firebaseConfig';
import Chatbots from './chatbot/Chatbots';
import Leads from './leads/Leads';
import { signOut } from 'firebase/auth';
import Overview from './overview/Overview';
import { CircularProgress } from '@mui/material';

const NAVIGATION: Navigation = [
  {
    segment: 'overview',
    title: 'Overview',
    icon: <FlareIcon />,
  },
  {
    segment: 'chatbots',
    title: 'Chatbots',
    icon: <Person3Icon />,
  },
  {
    segment: 'leads',
    title: 'Leads',
    icon: <TimelineIcon />,
  },
];

const customTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#3f51b5',
        },
        secondary: {
          main: '#e64a19',
        },
        background: {
          default: '#F9F9FE',
          paper: '#EEEEF9',
        },
      },
    },
    dark: {
      palette: {
        background: {
          default: '#2A4364',
          paper: '#112E4D',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

function PageContent({
  pathname,
  vendorRef,
}: {
  pathname: string;
  vendorRef: DocumentReference;
}) {
  console.log(pathname);
  switch (pathname) {
    case '/chatbots':
      return <Chatbots vendorRef={vendorRef} />;
    case '/leads':
      return <Leads vendorRef={vendorRef} />;
    default:
      return <Overview vendorRef={vendorRef} />;
  }
}

export default function AppProviderTheme() {
  const [pathname, setPathname] = useState('/');
  const [vendorRefId, setVendorRefId] = useState<DocumentReference>();
  const [session, setSession] = React.useState<Session | null>();
  const { currentUser } = useAuth();
  console.log(currentUser);

  useEffect(() => {
    setSession({
      user: {
        id: currentUser?.uid,
        name: currentUser?.displayName,
        email: currentUser?.email,
        image: currentUser?.photoURL,
      },
    });
    const fetchAndSetVendor = async () => {
      const userDocRef = doc(db, `users/${currentUser?.uid}`);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        setVendorRefId(userDocSnap.data().vendorRefId);
      }
    };
    fetchAndSetVendor();
  }, [currentUser]);

  const router = useMemo<Router>(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);

  const authentication = useMemo(() => {
    return {
      signIn: () => {
        setSession({
          user: {
            id: currentUser?.uid,
            name: currentUser?.displayName,
            email: currentUser?.email,
            image: currentUser?.photoURL,
          },
        });
      },
      signOut: async () => {
        try {
          await signOut(auth);
          setSession(null);
        } catch (error) {
          console.error('Error logging out:', error);
        }
      },
    };
  }, [currentUser]);

  return (
    <AppProvider
      branding={{
        title: 'VinTin',
      }}
      session={session}
      authentication={authentication}
      navigation={NAVIGATION}
      router={router}
      theme={customTheme}
    >
      <DashboardLayout>
        {!vendorRefId ? (
          <CircularProgress />
        ) : (
          <PageContent pathname={pathname} vendorRef={vendorRefId} />
        )}
      </DashboardLayout>
    </AppProvider>
  );
}
