import React, { useState } from 'react';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { SendRounded } from '@mui/icons-material';

interface ChatInputProps {
  onSendMessage: (message: string) => void;
  isLoading: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, isLoading }) => {
  const [message, setMessage] = useState<string>('');

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage(message.trim());
      setMessage('');
    }
  };

  return (
    <Stack direction="row" spacing={1} className="p-4">
      <TextField
        fullWidth
        placeholder="Type your message..."
        multiline
        maxRows={5}
        variant="standard"
        value={message}
        disabled={isLoading}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSend();
          }
        }}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  disabled={!message.trim()}
                  onClick={handleSend}
                  aria-label="Send Message"
                >
                  <SendRounded
                    color={message.length ? 'primary' : 'disabled'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          },
        }}
      />
    </Stack>
  );
};

export default ChatInput;
