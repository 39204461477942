import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Lead } from '../../../types';
import {
  collection,
  DocumentReference,
  getDocs,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import LeadDetails from './LeadDetails';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { Drawer } from 'antd';

interface LeadProps {
  vendorRef: DocumentReference;
}

const Leads: React.FC<LeadProps> = ({ vendorRef }) => {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const leadsRef = collection(db, 'leads');
        const q = query(leadsRef, where('vendorRefId', '==', vendorRef));
        const querySnapshot = await getDocs(q);
        const leadsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as Omit<Lead, 'id'>),
        }));
        setLeads(leadsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching leads:', error);
        setLoading(false);
      }
    };
    fetchLeads();
  }, [vendorRef]);

  const showDrawer = (lead: Lead) => {
    setSelectedLead(lead);
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const columns: GridColDef<Lead>[] = [
    {
      headerName: 'Name',
      field: 'name',
      type: 'string',
    },
    {
      headerName: 'Email',
      field: 'email',
      type: 'string',
      width: 150,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      type: 'string',
      width: 150,
    },
    {
      headerName: 'Location',
      field: 'location',
      type: 'string',
    },
    {
      headerName: 'Timestamp',
      field: 'createdAt',
      type: 'dateTime',
      width: 170,
      valueGetter: (value: Timestamp) => value && new Date(value.toDate()),
    },
    {
      headerName: 'Product',
      field: 'primaryProduct',
      type: 'string',
      width: 150,
    },
    {
      headerName: 'Confidence',
      field: 'leadConversionConfidenceOutOfHundred',
      type: 'number',
      width: 120,
    },
    {
      headerName: 'Action',
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <Button
          variant="text"
          onClick={() => {
            showDrawer(params.row);
          }}
        >
          View
        </Button>,
      ],
    },
  ];

  return (
    <Box className="p-2" sx={{ maxWidth: '95vw' }}>
      <Typography gutterBottom variant="h4">
        Leads
      </Typography>
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
          }}
          columns={columns}
          rows={leads}
        />
      )}
      {/* Drawer Component */}
      <Drawer
        title={`Lead Details - ${selectedLead?.name}`}
        placement="right"
        onClose={closeDrawer}
        open={isDrawerVisible}
        size="large"
        zIndex={2000}
      >
        {selectedLead && <LeadDetails lead={selectedLead} />}
      </Drawer>
    </Box>
  );
};

export default Leads;
