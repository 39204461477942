import React, { useEffect, useState } from 'react';
import { Chatbot, Lead, Message } from '../../../types';
import { getDoc } from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';
import {
  Box,
  CircularProgress,
  Divider,
  Grid2 as Grid,
  Typography,
} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

interface LeadDetailsProps {
  lead: Lead;
}

const LeadDetails: React.FC<LeadDetailsProps> = ({ lead }) => {
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [chatBotDetails, setChatBotDetails] = useState<Chatbot>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchChat = async () => {
      try {
        const chatSnap = await getDoc(lead.chatRefId);
        if (chatSnap.exists()) {
          const chatData = chatSnap.data();
          setChatMessages(chatData.messages);
        } else {
          console.error('No such chat document!');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching chat:', error);
        setLoading(false);
      }
    };
    const fetchChatBot = async () => {
      try {
        const chatBotSnap = await getDoc(lead.chatbotRefId);
        if (chatBotSnap.exists()) {
          const chatBotData = chatBotSnap.data();
          setChatBotDetails(chatBotData);
        } else {
          console.error('No such chat document!');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching chat:', error);
        setLoading(false);
      }
    };

    fetchChat();
    fetchChatBot();
  }, [lead]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Box className="pb-2">
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, md: 6 }}>
            <p>
              <strong>Name:</strong> {lead.name}
            </p>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <p>
              <strong>Email:</strong> {lead.email}
            </p>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <p>
              <strong>Phone:</strong> {lead.phone}
            </p>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <p>
              <strong>Location:</strong> {lead.location}
            </p>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <p>
              <strong>Age:</strong> {lead.age}
            </p>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <p>
              <strong>Preferred Time Slot:</strong> {lead.preferredTimeSlot}
            </p>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <p>
              <strong>Primary Product Category:</strong>{' '}
              {lead.primaryProductCategory}
            </p>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <p>
              <strong>Confidence:</strong>{' '}
              {lead.leadConversionConfidenceOutOfHundred}
            </p>
          </Grid>
          <p>
            <strong>Created At:</strong>{' '}
            {`${new Date(lead.createdAt.toDate())}`}
          </p>
          <p>
            <strong>Other Opportunities:</strong>{' '}
            {lead.otherOpportunities.join(' | ')}
          </p>
          <p>
            <strong>Relevant Keywords:</strong>{' '}
            {lead.relevantKeywords.join(' | ')}
          </p>
          <p>
            <strong>Summary:</strong> {lead.summary}
          </p>
          <p>
            <strong>Other Notes:</strong> {lead.anyOtherNotes}
          </p>
        </Grid>
      </Box>
      {/* Add more lead fields as necessary */}
      <Divider />
      <Box className="py-2">
        <Typography gutterBottom variant="h6">
          Chat Transcript
        </Typography>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {chatMessages.map((message, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  color={message.role === 'assistant' ? 'primary' : 'secondary'}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <p>
                  <strong>
                    {message.role === 'assistant'
                      ? chatBotDetails?.name
                      : lead.name || 'User'}
                    :
                  </strong>
                </p>
                <ReactMarkdown
                  components={{
                    p(props) {
                      const { node, ...rest } = props;
                      return (
                        <p
                          style={{ lineHeight: 1.65, letterSpacing: '0.2px' }}
                          {...rest}
                        />
                      );
                    },
                    ol(props) {
                      const { node, ...rest } = props;
                      return (
                        <ol
                          style={{
                            paddingLeft: '1.5rem',
                            paddingTop: '0.25rem',
                            paddingBottom: '0.25rem',
                            listStyleType: 'circle',
                          }}
                          {...rest}
                        />
                      );
                    },
                    ul(props) {
                      const { node, ...rest } = props;
                      return (
                        <ul
                          style={{
                            paddingLeft: '1.5rem',
                            paddingTop: '0.25rem',
                            paddingBottom: '0.25rem',
                            listStyleType: 'circle',
                          }}
                          {...rest}
                        />
                      );
                    },
                  }}
                >
                  {message.content}
                </ReactMarkdown>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </Box>
  );
};

export default LeadDetails;
