import React, { useState } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Collapse,
  Fade,
  Link,
  Paper,
  InputAdornment,
} from '@mui/material';
import {
  ExpandMore,
  ExpandLess,
  LaunchRounded,
  SendRounded,
} from '@mui/icons-material';
import ChatApp from '../chat/Chat';

const MainLandingPage: React.FC = () => {
  const [query, setQuery] = useState<string>('');
  const [showFAQ, setShowFAQ] = useState<boolean>(false);
  const [showChat, setShowChat] = useState<boolean>(false);

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!query.trim()) return;
    setShowChat(true);
  };

  const toggleFAQ = () => {
    setShowFAQ((prev) => !prev);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0.5rem 1.5rem 0.5rem 1.5rem',
          bgcolor: 'white',
        }}
      >
        {/* Logo */}

        <Link
          href="/"
          underline="none"
          sx={{ display: 'flex', alignItems: 'start' }}
        >
          <Typography variant="h4" color="primary.dark">
            VinTin
          </Typography>
          <Typography variant="body2" color="primary.dark">
            (beta)
          </Typography>
        </Link>

        <Link
          href="/vendor"
          variant="subtitle1"
          target="_blank"
          rel="noopener"
          color="primary.dark"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          VENDORS
          <LaunchRounded fontSize="small" sx={{ padding: 0.25 }} />
        </Link>
      </Box>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        {/* Landing Page or Chat Interface */}
        {!showChat ? (
          /* Landing Page View */
          <Fade in={!showChat}>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                px: 2,
                textAlign: 'center',
              }}
            >
              {/* Search Bar */}
              <Typography gutterBottom variant="h4">
                What are you looking for?
              </Typography>
              <form
                onSubmit={handleSearchSubmit}
                style={{ width: '100%', maxWidth: '50rem' }}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  autoFocus
                  placeholder="e.g: Want to invest 10K  |  Want to buy a car  |  Want to improve my skin"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10rem', // Set your desired border radius here
                    },
                  }}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton type="submit">
                            <SendRounded
                              color={query.length ? 'primary' : 'disabled'}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </form>
            </Box>
          </Fade>
        ) : (
          <Fade in={showChat}>
            <Box>
              <ChatApp initialQuery={query} />
            </Box>
          </Fade>
        )}
      </Box>

      {/* Footer with FAQ */}
      {!showChat && (
        <Box sx={{ padding: 2, textAlign: 'center' }}>
          <Typography gutterBottom variant="h5" color="grey.700">
            FAQs
            <IconButton onClick={toggleFAQ}>
              {showFAQ ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Typography>
          <Collapse in={showFAQ}>
            <Paper sx={{ padding: 2, textAlign: 'left' }}>
              {/* FAQs Content */}
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                What is VinTin?
              </Typography>
              <Typography>
                VinTin is an AI-powered platform designed to enhance your buying
                experience through intelligent human like conversations. VinTin
                aims to understand your requirements and give you personalized
                recommendations on how to move forward with your purchase
                decisions. In the end, it connects you with experts to help you
                complete your purchase.
              </Typography>
              {/* Add more FAQs as needed */}
            </Paper>
          </Collapse>
        </Box>
      )}
    </Box>
  );
};

export default MainLandingPage;
