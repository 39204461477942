// src/components/Dashboard/ChatbotCard.tsx

import React from 'react';
import { Chatbot } from '../../../types';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

interface ChatbotCardProps {
  chatbot: Chatbot;
  onEdit: (chatbot: Chatbot) => void;
}

const ChatbotCard: React.FC<ChatbotCardProps> = ({ chatbot, onEdit }) => {
  const baseUrl = 'https://vintin.ai';

  const handleCopyUrl = () => {
    const url = `${baseUrl}/c/${chatbot.id}`;
    navigator.clipboard.writeText(url).then(
      () => {
        // TODO: use a notification framework instead of browser alert.
        alert('URL copied to clipboard!');
      },
      (err) => {
        console.error('Could not copy text: ', err);
      }
    );
  };

  const handleEdit = () => {
    onEdit(chatbot);
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'secondary.light' }} aria-label="Chatbot">
            {chatbot.name.charAt(0)}
          </Avatar>
        }
        action={
          <IconButton aria-label="edit bot" onClick={handleEdit}>
            <BorderColorOutlinedIcon />
          </IconButton>
        }
        title={
          <Typography gutterBottom variant="h6">
            {chatbot.name} - {chatbot.industry}
          </Typography>
        }
      />
      <CardContent>
        <Typography gutterBottom variant={'body1'}>
          <strong>Description</strong>: {chatbot.description || 'N/A'}
        </Typography>
        <Typography gutterBottom variant={'body1'}>
          <strong>Greeting Message</strong>: {chatbot.greetingMessage || 'N/A'}
        </Typography>
        <Typography variant={'body1'}>
          <strong>Instructions</strong>: Click Edit to see!
        </Typography>
      </CardContent>
      <CardActions>
        {chatbot.published ? (
          <Button
            startIcon={<ContentCopyOutlinedIcon className="h-6 w-6 mr-2" />}
            variant="contained"
            onClick={handleCopyUrl}
          >
            Copy URL
          </Button>
        ) : (
          <CircularProgress />
        )}
      </CardActions>
    </Card>
  );
};

export default ChatbotCard;
