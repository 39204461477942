import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid2 as Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import KeyMetricsCard from './KeyMetricsCard';
import LeadTrafficChart from './LeadTrafficChart';
import LeadsByIndustry from './LeadsByIndustry';
import { db } from '../../../firebaseConfig';
import {
  collection,
  query,
  where,
  getDocs,
  DocumentReference,
} from 'firebase/firestore';
import { Lead, Chatbot, PieData } from '../../../types';

interface OverviewProps {
  vendorRef: DocumentReference;
}

interface KpiCardData {
  title: string;
  value: number;
  gradientColor: string;
}

const Overview: React.FC<OverviewProps> = ({ vendorRef }) => {
  const [leadsByProduct, setLeadsByProduct] = useState<PieData[]>([]);
  const [kpiData, setKpiData] = useState<KpiCardData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch chatbots
      const chatbotsRef = collection(db, 'chatbots');
      const chatbotsQuery = query(
        chatbotsRef,
        where('vendorRefId', '==', vendorRef)
      );
      const chatbotsSnapshot = await getDocs(chatbotsQuery);
      const chatbotsData = chatbotsSnapshot.docs.map(
        (doc) => doc.data() as Chatbot
      );

      // Fetch leads
      const leadsRef = collection(db, 'leads');
      const leadsQuery = query(leadsRef, where('vendorRefId', '==', vendorRef));
      const leadsSnapshot = await getDocs(leadsQuery);
      const leadsData = leadsSnapshot.docs.map((doc) => doc.data() as Lead);
      const qualifiedLeadsCount = leadsData.filter(
        (lead) => lead.leadConversionConfidenceOutOfHundred > 70
      ).length;

      setKpiData([
        {
          title: 'Total Chatbots',
          value: chatbotsData.length,
          gradientColor: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        },
        {
          title: 'Total Leads',
          value: leadsData.length,
          gradientColor: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        },
        {
          title: 'Qualified Leads',
          value: qualifiedLeadsCount,
          gradientColor: 'linear-gradient(45deg, #66BB6A 30%, #B2FF59 90%)',
        },
      ]);

      // Process leads by product
      const productCounts: { [key: string]: number } = {};
      leadsData.forEach((lead) => {
        const product = lead.primaryProductCategory || 'Unknown';
        productCounts[product] = (productCounts[product] || 0) + 1;
      });

      const productData = Object.keys(productCounts).map((product) => ({
        label: product,
        value: productCounts[product],
      }));
      setLeadsByProduct(productData);
      setLoading(false);
    };

    fetchData();
  }, [vendorRef]);

  return (
    <Box className="p-2" sx={{ maxWidth: '95vw' }}>
      <Typography variant="h4" gutterBottom>
        Overview
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container rowSpacing={5}>
          <Grid size={12}>
            <Paper square={false} elevation={2} className="p-4">
              <Typography variant="h6" gutterBottom>
                Key Metrics
              </Typography>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 2, sm: 3, md: 4 }}
                sx={{ alignItems: 'center' }}
              >
                {kpiData.map((card, index) => (
                  <KeyMetricsCard {...card} />
                ))}
              </Stack>
            </Paper>
          </Grid>
          {/* Pie Charts */}
          <Grid size={12}>
            <LeadsByIndustry
              data={leadsByProduct}
              totalLeads={kpiData[1]?.value || 0}
            />
          </Grid>
          {/* Line Chart */}
          <Grid size={12}>
            <LeadTrafficChart />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Overview;
