import React, { useEffect, useRef } from 'react';
import { Message } from '../../types';
import ChatMessage from './ChatMessage';
import { Skeleton, Stack } from '@mui/material';

interface ChatWindowProps {
  messages: Message[];
  isLoading: boolean;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ messages, isLoading }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // Auto-scroll to the latest message
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <Stack
      spacing={2}
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        padding: 2,
      }}
    >
      {messages.map(
        (message, index) =>
          message.content && (
            <ChatMessage key={index} message={message}></ChatMessage>
          )
      )}
      {isLoading && (
        <Stack>
          <Skeleton width={270} />
          <Skeleton width={220} />
          <Skeleton width={340} />
          <Skeleton width={250} />
        </Stack>
      )}
      <div ref={messagesEndRef} />
    </Stack>
  );
};

export default ChatWindow;
