// KeyMetricsCard.tsx
import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid2 as Grid,
  styled,
} from '@mui/material';

interface KeyMetricsCardProps {
  title: string;
  value: number;
  gradientColor: string;
}

const GradientCard = styled(Card)<{ gradientColor: string }>(
  ({ gradientColor }) => ({
    background: gradientColor,
    color: 'white',
  })
);

const KeyMetricsCard: React.FC<KeyMetricsCardProps> = ({
  title,
  value,
  gradientColor,
}) => {
  return (
    <Grid container size={{ xs: 12, sm: 6, md: 3 }}>
      <GradientCard gradientColor={gradientColor}>
        <CardContent>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="h4">{value}</Typography>
        </CardContent>
      </GradientCard>
    </Grid>
  );
};

export default KeyMetricsCard;
