import React from 'react';
import { Helmet } from 'react-helmet';

const Metadata: React.FC = () => {
  return (
    <Helmet>
      <title>VinTin.ai - Revolutionizing Business Growth</title>
      <meta
        name="description"
        content="VinTin.ai enhances your business growth with innovative AI solutions. Say goodbye to cold calling and embrace the future of communication."
      />
      <meta name="robots" content="index, follow" />
      {/* Open Graph Meta Tags for Social Media */}
      <meta
        property="og:title"
        content="VinTin.ai - Revolutionizing Business Growth"
      />
      <meta
        property="og:description"
        content="Enhance your sales and reception with VinTin.ai's innovative solutions."
      />
      <meta property="og:image" content="https://VinTin.ai/logo192.png" />
      <meta property="og:url" content="https://VinTin.ai" />
      {/* Twitter Card Meta Tags */}
      <meta
        name="twitter:title"
        content="VinTin.ai - Revolutionizing Business Growth"
      />
      <meta
        name="twitter:description"
        content="Enhance your Business Growth with VinTin.ai's innovative solutions."
      />
      <meta name="twitter:image" content="https://VinTin.ai/logo512.png" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

export default Metadata;
